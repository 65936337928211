import axios from 'axios';
import { URLS } from './allurl.js';

export function insertNewBlog(params) {
  const url = URLS.BLOGS.INSERT_BLOG.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function loadBlogList(params) {
  const url = URLS.BLOGS.BLOG_LIST.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function searchBlogById(params) {
  const url = URLS.BLOGS.BLOG_BY_ID.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function recordVisitorInfo(params) {
  const url = URLS.BLOGS.RECORD_VISITOR_INFO.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function searchRelatedBlogs(params) {
  const url = URLS.BLOGS.RELATED_BLOGS.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}
export function clickLikes(params) {
  const url = URLS.BLOGS.CLICK_LIKES.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}
export function ifUserClickedLikesIcon(params) {
  const url = URLS.BLOGS.IF_CLICKED_LIKES.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function getBlogLikesNum(params) {
  const url = URLS.BLOGS.BLOG_LIKES_NUM.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function searchBlogByUId(params) {
  return axios({
    method: 'post',
    url: URLS.BLOGS.BLOG_BY_UID.url,
    data: params,
  });
}

export function editMyBlog(params) {
  return axios({
    method: 'post',
    url: URLS.BLOGS.EDIT_MY_BLOG.url,
    data: params,
  });
}

export function getBlogStarsNum(params) {
  return axios({
    method: 'post',
    url: URLS.BLOGS.BLOG_STARS_NUM.url,
    data: params,
  });
}

export function clickStars(params) {
  return axios({
    method: 'post',
    url: URLS.BLOGS.CLICK_STARS.url,
    data: params,
  });
}

export function ifUserClickedStar(params) {
  return axios({
    method: 'post',
    url: URLS.BLOGS.IF_CLICKED_STARS.url,
    data: params,
  });
}

export function srhUserStarsBlog(params) {
  return axios({
    method: 'post',
    url: URLS.BLOGS.SRH_STARS_BLOG.url,
    data: params,
  });
}

export function delBlogByBid(params) {
  return axios({
    method: 'post',
    url: URLS.BLOGS.DEL_BY_BID.url,
    data: params,
  });
}

export function countSiteBaseData() {
  return axios({
    method: 'post',
    url: URLS.BLOGS.SITE_BASE_DATA.url,
  });
}

export default { insertNewBlog, loadBlogList, searchBlogById, recordVisitorInfo, searchRelatedBlogs, clickLikes,
  ifUserClickedLikesIcon, getBlogLikesNum, searchBlogByUId, editMyBlog, getBlogStarsNum, clickStars, ifUserClickedStar,
  srhUserStarsBlog, delBlogByBid, countSiteBaseData }