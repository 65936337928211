import axios from 'axios'
import { getUserId } from './account.js';

class EditorUploader {
  constructor( loader ) {
    // The file loader instance to use during the upload.
    this.loader = loader;
}
  async upload() {
    const data = new FormData()
    data.append('image', await this.loader.file)
    data.append('uid', getUserId())
    return new Promise((resolve, reject) => {
      axios({
        url: '/blog/uploadImage',
        method: 'post',
        data: data
      }).then(res=>{
        // console.log('res', res)
        let resData = res.data;
        resData.default = res.data.url;
        // console.log('resData', resData)
        resolve(resData)
      })
    })
  }
}

export default EditorUploader;