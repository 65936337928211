import axios from 'axios';
import { URLS } from './allurl.js';

export function loadAllTags() {
  const url = URLS.TAGS.GET_ALL_TAGS.url;
  return axios({
    method: 'post',
    url: url,
  });
}

export function loadHotTags(params) {
  const url = URLS.TAGS.HOT_TAGS.url;
  return axios({
    method: 'post',
    url: url,
    data: params
  });
}

export default { loadAllTags, loadHotTags }